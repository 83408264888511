exports.components = {
  "component---src-pages-2021-index-js": () => import("./../../../src/pages/2021/index.js" /* webpackChunkName: "component---src-pages-2021-index-js" */),
  "component---src-pages-2021-outcomes-js": () => import("./../../../src/pages/2021/outcomes.js" /* webpackChunkName: "component---src-pages-2021-outcomes-js" */),
  "component---src-pages-2021-panellists-js": () => import("./../../../src/pages/2021/panellists.js" /* webpackChunkName: "component---src-pages-2021-panellists-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archived-pages-petitions-clean-air-2024-js": () => import("./../../../src/pages/archived-pages/petitions/clean-air-2024.js" /* webpackChunkName: "component---src-pages-archived-pages-petitions-clean-air-2024-js" */),
  "component---src-pages-archived-pages-petitions-index-js": () => import("./../../../src/pages/archived-pages/petitions/index.js" /* webpackChunkName: "component---src-pages-archived-pages-petitions-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-organisations-js": () => import("./../../../src/pages/organisations.js" /* webpackChunkName: "component---src-pages-organisations-js" */),
  "component---src-pages-outcomes-js": () => import("./../../../src/pages/outcomes.js" /* webpackChunkName: "component---src-pages-outcomes-js" */),
  "component---src-pages-panellists-js": () => import("./../../../src/pages/panellists.js" /* webpackChunkName: "component---src-pages-panellists-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */)
}

