/** Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
// import "./src/styles/tailwind.base.css"
import "./src/styles/application.css"
// import "./src/styles/tailwind.after.css"

import "@reach/dialog/styles.css"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/zoom"

import React from "react"
import { ContextProvider } from "./src/context"

export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>
}
