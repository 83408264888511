import React, { useState } from "react"

export const Context = React.createContext()

const ContextProvider = props => {
  const [dayNum, setDayNum] = useState(0)

  return (
    <Context.Provider
      value={{
        dayNum,
        setDayNum,
      }}
    >
      {props.children}
    </Context.Provider>
  )
}

export { Context as default, ContextProvider }
